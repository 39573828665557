import React from 'react';
import './ArticleDetail.css'; // Add the CSS styles here or use a separate CSS file
import NewNavbar from './NewNavbar';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const ArticleDetail = () => {
    const { state } = useLocation();
    const { card, aboutData, title } = state || {};
    console.log(aboutData, "card")


    // const parser = new DOMParser();
    // const doc = parser.parseFromString(card.content || aboutData, 'text/html');
    // const text = doc.body.textContent || "";

    return (
        <div className="article-detail-container">
            {/* <div> */}
            <img src='https://demo-api.profcongress.in/v1/home/image/articles.png' alt="home" className="detail-image" />
            <div className="detail-banner">
                <h1 className="detail-title">{title ? title : `${card?.title || ''} ${card?.subtitle || ''}`}</h1>
            </div>
            {/* </div> */}
            <NewNavbar />

            {/* Article Section */}
            <section className="detail">

                <div className="detail-content container">
                    {/* <p className="detail-date">17 June, 24</p> */}
                    <div
                        className="content-para" style={{textAlign: 'left'}}
                        dangerouslySetInnerHTML={{ __html: card?.content ? card.content : aboutData }} />
                </div>
            </section>
            <div style={{ position: 'relative', top: '-7vh' }}>
                <Footer />
            </div>
        </div>
    );
};

export default ArticleDetail;
