import { useLocation } from "react-router-dom";
import React from 'react';
import Footer from "./Footer";
import Header from "./Header";
import './CAVerticals.css';
import { useState } from 'react';
import arrowright from '../../Assets/ArrowRight.svg';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewNavbar from "./NewNavbar";


const CAVerticals = () => {
  const { state } = useLocation();
  const { veritical } = state || {};
  const navigate = useNavigate();
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
      setIsOpen(!isOpen);
  };
  
  const verticalData = (veritical) => {
    navigate('/hierarchy', { state: { veritical } })
  }
  
  return (
    <div>
      <div className="container-fluid2">
        <NewNavbar />
        <div className="main-heading" style={{display:"flex", height: "560px", flexDirection:"column", justifyContent:"center"}}>
        <h1>{veritical.title} Verticals</h1>
        </div>
      </div>
        
      <main className="main-content">
        <section className="Verticals-section">
          <div className="Vertical">
            <div className="icon activity-icon"></div>
            <div className="insidediv">
              <h2>Activity</h2>
              <p>
                Commitment is to build a financially inclusive society where
                every citizen benefits from prudent economic policies and fair
                financial practices.
              </p>
              <a href="activity" className="arrow-link">
                <img src={arrowright} style={{cursor:"pointer"}} alt="arrow" className="arrow-image" />
              </a>
            </div>
          </div>
          <div className="Vertical">
            <div className="icon hierarchy-icon"></div>
            <div className="insidediv">
              <h2>Hierarchy</h2>
              <p>
                Commitment is to build a financially inclusive society where
                every citizen benefits from prudent economic policies and fair
                financial practices.
                <a onClick={() => verticalData(veritical)} className="arrow-link">
                  <img src={arrowright} style={{cursor:"pointer"}} alt="arrow" className="arrow-image" />
                </a>
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default CAVerticals;