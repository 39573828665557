import Footer from "./Footer";
import NewNavbar from "./NewNavbar";
import './CAVerticals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import contentData from '../../Data/contentData.json';

const BecomeMember = () => {

  const becomeMemberData = contentData.becomeMember;

  return (
    <div>
      <div className="container-fluid2">
        <NewNavbar />
        <div className="main-heading" style={{ display: "flex", height: "560px", flexDirection: "column", justifyContent: "center" }}>
          <h1>{becomeMemberData.title}</h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
      {becomeMemberData.description.map((desc, index) => (
          <div 
            key={index} 
            style={{ fontSize: "20px", fontWeight: "500",  textAlign: "left", marginLeft: "50px", marginRight: "50px"}}
            dangerouslySetInnerHTML={{ __html: desc.text }}  // Correctly closed and formatted
          />
        ))}
        <button
          type="submit"
          onClick={() => window.location.href = becomeMemberData.button.url}
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "250px",
            marginBottom: "30px",
            marginTop: "30px",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
        >
          {becomeMemberData.button.text}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default BecomeMember;