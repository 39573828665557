import React, { useEffect } from "react";
import './TwitterFeed.css'; // Styling (optional)
import DownArrow from '../../Assets/downArrow.svg';
import twitterData from '../../Data/twitterData.json';
const TwitterFeed = () => {

    useEffect(() => {
        // Dynamically load the Twitter widget script
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section className="twit">
            <div className="col mt-5 " style={{ textAlign: 'left', width: '93%' }}>
                <div style={{display: 'flex'}}>
                    <h2 className="twitter-heading">Twitter Feeds</h2>
                    <img src={DownArrow} alt="Down Arrow" style={{ width: '34px', paddingLeft: '7px' }} />
                </div>
                <div className="twitter-feed">
                    {/* <h2>Twitter Feeds</h2> */}

                    {/* Embed individual tweets using Twitter's oEmbed */}
                    <div className="tweet-container">
                        <blockquote className="twitter-tweet">
                            <a className="twitter-timeline"
                                href={twitterData.feedUrl}>
                                Tweets by AIPC
                            </a>
                        </blockquote>
                    </div>

                    {/* <div className="tweet-container">
                <blockquote className="twitter-tweet">
                    <a href="https://twitter.com/Olympics/status/1433125303648731137"></a>
                </blockquote>
            </div> */}
                </div>
            </div>
        </section>
    );
};

export default TwitterFeed;
