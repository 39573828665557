import React from 'react';
import './MainContent.css';
import DownArrow from '../../Assets/downArrow.svg';
import { useNavigate } from 'react-router-dom';

function Verticals( {veriticals} ) {

    const navigate = useNavigate();

    const handleChange = () => {
        navigate('/verticals', { state: { veriticals } });
    }

    const verticalData = (veritical) => {
        navigate('/ca-verticals', { state: { veritical } })
        console.log(veritical, "test")
    }

    return (
        <>
            <section className="verticals">
                <div className="col-md-11">
                    <h4 className="verticals-heading" onClick={handleChange}>Verticals
                        <img src={DownArrow} alt="Down Arrow" style={{ width: '34px', paddingLeft: '7px' }} />
                    </h4>
                    <div className="vertical-cards row">
                        {veriticals && veriticals.slice(0, 3).map((veritical) => (
                            <div className="vertical col-md-4" style={{cursor:"pointer"}} key={veritical.id} onClick={() => verticalData(veritical)} >
                                <div className="vertical-overlay">
                                    <img
                                        className="vertical-image"
                                        src={veritical.imageUrl}
                                        alt={veritical.title}
                                    />
                                    <div className="vertical-title">{veritical.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Verticals
