import React, { useEffect, useState } from 'react';
import './Footer.css'; // Ensure you have the necessary CSS for styling
import dataFooter from '../../Data/footerData.json';
import logo from '../../Assets/logo.svg'; // Replace with the actual path to the logo
import youtubeIcon from '../../Assets/youtube.svg'; // Import your local icons
import linkedIcon from '../../Assets/linkedin.svg'; // Import your local icons
import instagramIcon from '../../Assets/insta.svg';
import facebookIcon from '../../Assets/face.svg';
import twitterIcon from '../../Assets/twit.svg';

const Footer = () => {
    const [footerData, setFooterData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            const updatedFooterData = {
                ...dataFooter,
                socialMedia: dataFooter.socialMedia.map((social) => {
                    let iconUrl;
                    switch (social.name) {
                        case 'linkedin':
                            iconUrl = linkedIcon;
                            break;
                        case 'Instagram':
                            iconUrl = instagramIcon;
                            break;
                        case 'Facebook':
                            iconUrl = facebookIcon;
                            break;
                        case 'Twitter':
                            iconUrl = twitterIcon;
                            break;
                        default:
                            iconUrl = null;
                    }
                    return { ...social, iconUrl };
                })
            };
            setFooterData(updatedFooterData);
            setLoading(false); // Set loading to false after the delay
        }, 2000); // Set your desired loading time here (2 seconds)

        return () => clearTimeout(timer); // Clean up the timer when the component unmounts
    }, []);

    if (loading) {
        return (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        );
    }


    // If data hasn't been fetched yet, return null or a loader
    if (!footerData) return null;

    return (
        <footer className="footer-container">
            <div className="row">
                <div className="contact-info col">
                    <img src={logo} alt="Company Logo" className="footer-logo" />
                </div>
                <div className="social-media col">
                    <h4>Follow Us</h4>
                    <div className="social-icons">
                        {footerData.socialMedia.map((social, index) => (
                            <a key={index} href={social.url} target="_blank" rel="noopener noreferrer">
                                <img src={social.iconUrl} alt={social.name} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4" style={{ textAlign: 'left' }}>
                    <p>{footerData.company.name}</p>
                    <p>Tel: {footerData.company.contact.phone}</p>
                    <p>Fax: {footerData.company.contact.fax}</p>
                    <p>Email: <a href={`mailto:${footerData.company.contact.email}`}>{footerData.company.contact.email}</a></p>
                </div>

                <div className="footer-links col-8">
                    <div className="row" style={{ textAlign: 'right'}}>
                        {footerData.links.map((link, index) => (
                            <div key={index} className="col-12 col-md-4 mb-4">
                                <a href={link.url}>{link.title}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>




        </footer>
    );
};

export default Footer;
