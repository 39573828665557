import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import CAVerticals from './Components/Home/CAVerticals.js';
import ShareYourConcern from './Components/Home/ShareYourConcern.js';
import BecomeMember from './Components/Home/BecomeMember.js';
import Hierarchy from './Components/Home/Hierarchy.js';
import ArticleDetail from './Components/Home/ArticleDetail.js';
import Article from './Components/Article.js';
import Watch from './Components/Home/Watch.js';
import VerticalsMain from './Components/Home/VerticalsMain.js';
import Activity from './Components/Home/Activity.js';
import ArticlePage from './Components/Article/ArticlePage.js';
import NewNavbar from './Components/Home/NewNavbar';
import TwitterFeed from './Components/Home/TwitterFeed.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ca-verticals" element={<CAVerticals/>} />
          <Route path="/hierarchy" element={<Hierarchy/>} />
          <Route path="/watch" element={<Watch/>} />
          <Route path="/share-your-concern" element={<ShareYourConcern/>} />
          <Route path="/become-a-member" element={<BecomeMember/>} />
          <Route path="/articles" element={<Article/>} />
          <Route path="/verticals" element={<VerticalsMain/>} />
          <Route path="/activity" element={<Activity/>} />
          <Route path="/article-detail" element={<ArticleDetail/>} />
          <Route path="/articlepage" element={<ArticlePage/>}/>
          <Route path="/new" element={<NewNavbar/>}/>
          <Route path="/twitterFeed" element={<TwitterFeed/>}></Route>
          {/* Add more routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;