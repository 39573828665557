import React, { useEffect, useState } from 'react';
import './Header.css'; // Create a CSS file for header styling
import NewNavbar from './NewNavbar';

const Header = ({ data, aboutData }) => {

    return (
        <div className="header-container">
            <img src={data.imageUrl} alt="home" className="background-image img-fluid" />
            <NewNavbar aboutData={aboutData} />
            <section className="hero">
                <span className="title-text">
                    {data.title.split(' ').slice(0, 2).join(' ')}
                </span>
                <span className="title-text">
                    {data.title.split(' ').slice(2).join(' ')}
                </span>
            </section>
        </div>


    );
};

export default Header;
