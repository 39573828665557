import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // This is important for styling
import axios from 'axios';
import './Gallery.css'; // Import the custom CSS

const Gallery = ({ images }) => {

  return (
    <div className="col gallery">
      <h4 className="gallery-heading">Gallery</h4>


      <Carousel
        showArrows={true}
        autoPlay={true} // Enable automatic transition
        infiniteLoop={true} // Enable infinite loop
        interval={3000} // Transition every 3 seconds
        showThumbs={false} // Hide thumbnails
        showStatus={false} // Hide status indicator
        stopOnHover={true} // Stop autoPlay on hover
      >
        {images.length > 0 ? (
          images.map((imgUrl, index) => (
            <div key={index} className="carousel-image-container">
              <img src={imgUrl} alt={`Gallery image ${index + 1}`} className="carousel-image" />
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </Carousel>
    </div>
  );
};

export default Gallery;
