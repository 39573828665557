import React, { useEffect, useState } from 'react';
import Footer from "./Footer";
import './Watch.css';
import { useNavigate } from 'react-router-dom';
import NewNavbar from './NewNavbar';

const Watch = () => {
    const navigate = useNavigate();
    const [articles, setArticles] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Simulate an API call or asset loading delay
        const timer = setTimeout(() => {
            // API call to fetch articles
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setArticles(result.Data.Articles); // Set the fetched articles
                    setLoading(false); // Disable loading after data is fetched
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false); // Disable loading even if there is an error
                });
        }, 2000); // Simulate a loading delay

        return () => clearTimeout(timer); // Clean up the timer when the component unmounts
    }, [apiUrl]);

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const handleChange = () => {
        navigate('/articles')
    }

    if (loading) {
        return (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div>
            <div className="container-fluid3">
                <NewNavbar />
                <div className="main-heading" style={{ display: "flex", height: "560px", flexDirection: "column", justifyContent: "center" }}>
                    <h1>Activity</h1>
                </div>
            </div>
            <main className="main-content">
                <section className="articles">

                    <div className="article-list container">
                        {articles && articles.map((article) => (
                            <div className="article-item row g-2 " key={article.id}>
                                <div className='col-3'>
                                    <img className="article-image img-fluid" src={article.imageUrl} alt={article.title} />
                                </div>
                                <div className="article-content col-9">
                                    {/* <p className="article-title">{article.title}</p> */}
                                    <p className="article-title">{article.details}</p>
                                    {article.hastag && <p className="article-hashtag">{article.hastag}</p>}
                                    <p className="article-source">{article.source}</p>
                                    <p className="article-time">{formatDateTime(article.createdTime).toLocaleString()}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default Watch;