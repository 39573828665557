import React, { useEffect, useState } from 'react';
import Footer from "./Footer";
import './Watch.css';
import DownArrow from '../../Assets/downArrow.svg';
import NewNavbar from './NewNavbar';

const Watch = () => {
  const [videos, setVideos] = useState([]);


  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
      setIsOpen(!isOpen);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://demo-api.profcongress.in/v1/home/videos/1/50');
        const result = await response.json();
        if (result.Success === 1) {
          setVideos(result.Data);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="container-fluid3">
      <NewNavbar />
        <div className="main-heading" style={{display:"flex", height: "560px", flexDirection:"column", justifyContent:"center"}}>
        <h1>Watch</h1>
        </div>
      </div>
      <main className="main-content1">
        <section className="video-grid">
          {videos.map((video) => (
            <div key={video.id} className="video-card">
              {video.isYouTube ? (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.utubeId}`}
                  title={video.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <img
                  src={video.thumbNailUrl || "default-thumbnail.jpg"}
                  alt={video.title}
                  className="thumbnail"
                />
              )}
              <div className="video-info">
                <h2>
                  <a
                    href={video.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    {video.title}
                  </a>
                </h2>
              </div>
            </div>
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Watch;