import React from "react";
import Footer from "./Footer";
import "./VerticalsMain.css";
import { useNavigate } from 'react-router-dom';
import NewNavbar from "./NewNavbar";
import { useLocation } from 'react-router-dom';


function VerticalsMain() {
  const location = useLocation();
  const veriticals = location.state?.veriticals || [];
  console.log("asaasa", veriticals)
  const navigate = useNavigate();
  
  const handleonclick = (veritical)=>{
          navigate('/ca-verticals', { state: { veritical } })
  }


  return (
    <div>
      <div className="container-fluid2">
      <NewNavbar/>
        <div
          className="main-heading"
          style={{
            display: "flex",
            height: "560px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h1>Verticals</h1>
        </div>
      </div>
      <section className="Verticals">
        <div className="Col-md-11">
          <div className="Vertical-cards ">
            {veriticals &&
              veriticals.map((veritical) => (
                <div className="Vertical" key={veritical.id}>
                  <div className="Vertical-overlay">
                    <img
                      className="Vertical-image1"
                      src={veritical.imageUrl}
                      alt={veritical.title}
                       onClick={()=> handleonclick(veritical)}
                       style={{ cursor: 'pointer' }}
                    />
                    <div className="Vertical-title" style={{cursor:'pointer'}} onClick={()=> handleonclick(veritical)}>{veritical.title}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default VerticalsMain;
