import ArticleHeader from './Article/ArticleHeader'
import ArticleList from './Article/ArticleList';
import Footer from './Home/Footer';

const Article=()=>{
    return <>
        <ArticleHeader></ArticleHeader>
        <ArticleList></ArticleList>
        <Footer></Footer>
    </>
    
}

export default Article;