import React, { useEffect, useState } from 'react';
import Header from './Home/Header';
import MainContent from './Home/MainContent';
import Articles from './Home/Article';
import Footer from './Home/Footer';
import './Home.css'
import TwitterFeed from './Home/TwitterFeed';

function App() {

    const [homeData, setHomeData] = useState(null); // Store the API data
    const [videos, setVideos] = useState(null);
    const [veriticals, setVerticals] = useState(null);
    const [articles, setArticles] = useState(null);
    const [images, setImages] = useState([]);
    const [aboutData, setAboutData] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setHomeData(result.Data.Home); // Set the Home data from the response
                setVideos(result.Data.Videos);
                setVerticals(result.Data.Categories)
                setImages(result.Data.Gallery);
                setArticles(result.Data.Articles);
                setAboutData(result.Data.Home.about);
                setLoading(false); // Set loading to false once data is fetched
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false); // Set loading to false even if there's an error
            });
    }, [apiUrl]);

    if (loading) {
        return (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="App">
            {homeData && (
                <>
                    <Header data={homeData} aboutData={aboutData} />
                    <MainContent data={homeData} videos={videos} veriticals={veriticals} images={images} />
                    <Articles articles={articles} />
                    <TwitterFeed />
                    <Footer />
                </>
            )}
        </div>
    );
}

export default App;
