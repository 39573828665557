import React, { useEffect, useState } from 'react';
import './Navbar.css'; // Link to the correct CSS file
import logo from '../../Assets/logo.svg'; // Update with the correct path to your logo
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Navbar = ( {aboutData} ) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleAboutClick = () => {
        navigate('/article-detail', { state: { aboutData: aboutData, title: 'About' } }); // Pass data and title using state
    };

    const handleBecome = () => {
        navigate('/become-a-member')
    };

    return (
        <nav className={`navbar navbar-expand-lg custom-navbar ${isOpen ? 'navbar-open' : ''}`} style={{ position: 'fixed' }}>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                {/* Logo */}
                <a className="navbar-brand" href="/">
                    <img className='logo' src={logo} alt="Logo" />
                </a>

                {/* Toggler/collapsible Button */}
                <button className="navbar-toggler" style={{ background: 'white' }} type="button" onClick={toggleNavbar}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Collapsible content */}
                <div className={`collapse navbar-collapse p-2 ${isOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center"
                        style={{ background: isOpen ? 'grey' : 'transparent', width: isOpen ? '70%' : '', padding: isOpen ? '2%' : '', borderRadius: isOpen ? '7%' : '' }}>
                        <li className="nav-item">
                            <a className="nav-link" onClick={handleAboutClick}>About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/share-your-concern">Share your Concern</a>
                        </li>
                        <li className="nav-item">
                            <button className="btn become-member-btn" onClick={ handleBecome }
                                style={{
                                    background: 'white',
                                    borderRadius: '20px',
                                    color: '#3578B7',
                                    fontWeight: 700
                                }}>
                                Become a Member
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
