import { useState } from 'react';
import Footer from "./Footer";
import NewNavbar from "./NewNavbar";
import './CAVerticals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import contentData from '../../Data/contentData.json';
import 'react-toastify/dist/ReactToastify.css';

const ShareYourConcern = () => {

  const shareConcernData = contentData.shareConcern;

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    concern: ''
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      name: formData.name,
      email: formData.email,
      phonenumber: formData.phone,
      concern: formData.concern,
    };

    try {
      const response = await fetch('https://demo-api.profcongress.in/v1/home/submitConcern', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.Success === 1) {
        toast.success(data.Message);  // Show success toast
        setTimeout(() => {
          navigate('/');  
        }, 3000);
      } else {
        toast.error('There was an issue submitting your concern. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting your concern. Please try again later.');
    }
  };

  return (
    <div>
      <div className="container-fluid2">
        <NewNavbar />
        <div className="main-heading" style={{ display: "flex", height: "560px", flexDirection: "column", justifyContent: "center" }}>
          <h1>{shareConcernData.title}</h1>
        </div>
      </div>

      <div className="container" style={{ textAlign: "center", marginTop: "40px" }}>
      {shareConcernData.description.map((desc, index) => (
          <div 
            key={index} 
            style={{ fontSize: "20px", fontWeight: "500", textAlign: "left", marginLeft: "50px", marginRight: "50px", marginBottom: "40px"}}
            dangerouslySetInnerHTML={{ __html: desc.text }}  // Correctly closed and formatted
          />
        ))}
        <form onSubmit={handleSubmit} style={{ width: "80%", marginTop: "60px", marginBottom: "60px", margin: "auto" }}>
          <input
            type="text"
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            style={{ width: "100%", padding: "10px", marginBottom: "40px", border: "1px solid #646363", borderRadius: "4px" }}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Enter Phone Number"
            value={formData.phone}
            onChange={handleChange}
            style={{ width: "100%", padding: "10px", marginBottom: "40px", border: "1px solid #646363", borderRadius: "4px" }}
          />
          <input
            type="email"
            name="email"
            placeholder="Enter Email-Id"
            value={formData.email}
            onChange={handleChange}
            style={{ width: "100%", padding: "10px", marginBottom: "40px", border: "1px solid #646363", borderRadius: "4px" }}
          />
          <textarea
            name="concern"
            placeholder="Concern"
            value={formData.concern}
            onChange={handleChange}
            style={{ width: "100%", padding: "10px", marginBottom: "20px", height: "100px", border: "1px solid #646363", borderRadius: "4px" }}
          />
          <button
            type="submit"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "150px",
              marginBottom: "60px"
            }}
          >
            {shareConcernData.button.text}
          </button>
        </form>
      </div>

      <Footer />

      {/* Toast container */}
      <ToastContainer />
    </div>
  );
};

export default ShareYourConcern;