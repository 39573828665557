import React, { useEffect, useState } from 'react';
import './MainContent.css'; // Create a CSS file for main content styling
import DownArrow from '../../Assets/downArrow.svg';
import { useNavigate } from 'react-router-dom';


const Articles = ( {articles} ) => {

    const navigate = useNavigate();

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const handleClick = (article) => {
        if (article.isOpenOutside) {
            window.location.href = article.link;
        }
        else {
            navigate(`/articlepage?id=${article.id}`, { state: { article } });
        }

    }

    const handleChange = () => {
        navigate('/articles')
    }

    return (
        <section className="articles">
            <div className="col-md-11 mt-5" style={{ textAlign: 'left' }}>
                <h4 className="articles-heading" onClick={handleChange}>
                    Articles
                    <img src={DownArrow} alt="Down Arrow" style={{ width: '34px', paddingLeft: '7px' }} />
                </h4>
                <div className="article-list container">
                    {articles && articles.map((article) => (
                        <div className="article-item row g-2 " style={{ width: '100%' }} key={article.id} onClick={() => { handleClick(article) }}>
                            <div className='col-3'>
                                <img className="article-image img-fluid" src={article.imageUrl} alt={article.title} />
                            </div>
                            <div className="article-content col-9">
                                {/* <p className="article-title">{article.title}</p> */}
                                <p className="article-title">{article.details}</p>
                                {article.hastag && <p className="article-hashtag">{article.hastag}</p>}
                                <p className="article-source">{article.source}</p>
                                <p className="article-time">{formatDateTime(article.createdTime).toLocaleString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Articles;