import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ArticleList.css'; // Create a CSS file for main content styling

const ArticleList = () => {

    const navigate= useNavigate();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://demo-api.profcongress.in/v1/home/articles/1/50", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setArticles(result.Data);
            })
            .catch((error) => console.error(error));
    }, []);

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const handleClick=(article)=>{
        if(article.isOpenOutside){
            window.location.href=article.link;
        }
        else{
            navigate(`/articlepage?id=${article.id}`,{state: {article}});
        }
    }


    return (
        <div className='container-fluid4'>
            <div className="article-lst container">
                    {articles && articles.map((article) => (
                        <div onClick={()=>{handleClick(article)}} className="article-itm row g-2 " key={article.id}>
                            <div className='col-3'>
                                <img className="article-img img-fluid" src={article.imageUrl} alt={article.title} />
                            </div>
                            <div className="article-cxt col-9">
                                {/* <p className="article-title">{article.title}</p> */}
                                <p className="article-ttl">{article.title}</p>
                                {article.hastag && <p className="article-hash">{article.hastag}</p>}
                                <p className="article-src">{article.source}</p>
                                <p className="article-clock">{formatDateTime(article.createdTime).toLocaleString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
        </div>
    );
};

export default ArticleList;
