import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import './Hierarchy.css';
import { useLocation } from "react-router-dom";
import FacebookIcon from '../../Assets/face.svg';
import XIcon from '../../Assets/twit.svg';
import LinkedInIcon from '../../Assets/linkedin.svg';
import InstagramIcon from '../../Assets/insta.svg';
import NewNavbar from "./NewNavbar";

const Hierarchy = () => {
  const { state } = useLocation();
  const { veritical } = state || {};
  const [coordinators, setCoordinators] = useState({ "National Coordinators": [], "State Coordinators": [] });
  const [loading, setLoading] = useState(true);
  console.log(veritical, "vertical data");
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
      setIsOpen(!isOpen);
  };
  

  useEffect(() => {
    fetch(`https://demo-api.profcongress.in/v1/home/categoryHierarchy/${veritical.id}`)
      .then(response => response.json())
      .then(data => {
        if (data.Success === 1) {
          setCoordinators(data.Data);
        }
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can customize this loading state as needed
  }

  return (
    <div>
      <div className="container-fluid1">
      <NewNavbar/>
        <div className="main-heading" style={{display:"flex", height: "560px", flexDirection:"column", justifyContent:"center"}}>
        <h1>{veritical.title} Hierarchy</h1>
        </div>
      </div>
        
      <div className="main-container">
        {Object.keys(coordinators).map((section) => (
          <div key={section} className="section">
            <h2>{section}</h2>
            <div className="card-container">
              {coordinators[section].map((coordinator) => (
                <div key={coordinator.id} className="main-card">
                  <img
                    src={coordinator.icon}
                    alt={`${coordinator.name}'s icon`}
                    className="image-icon"
                  />
                  <div className="inside-card">
                    <p>
                      <strong>{coordinator.name}</strong>
                    </p>
                    <p>{coordinator.profession}</p>
                    {coordinator.phoneno && <p>+91-{coordinator.phoneno}</p>}
                    <div className="social-links">
                      {coordinator.xId && (
                        <a
                          href={coordinator.xId}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={XIcon}
                            alt="X Icon"
                            className="social-icon"
                          />
                        </a>
                      )}
                      {coordinator.fbId && (
                        <a
                          href={coordinator.fbId}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={FacebookIcon}
                            alt="Facebook Icon"
                            className="social-icon"
                          />
                        </a>
                      )}
                      {coordinator.linkedInId && (
                        <a
                          href={coordinator.linkedInId}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={LinkedInIcon}
                            alt="LinkedIn Icon"
                            className="social-icon"
                          />
                        </a>
                      )}
                      {coordinator.InstagramId && (
                        <a
                          href={coordinator.InstagramId}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={InstagramIcon}
                            alt="Instagram Icon"
                            className="social-icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Hierarchy;
