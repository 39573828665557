import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import pageImage from '../../Assets/Home.jpg'
import './ArticleHeader.css'
import './ArticlePage.css'
import NewNavbar from "../Home/NewNavbar";
import Footer from '../Home/Footer'


const ArticlePage=()=>{
    const location = useLocation();
    const current =location.state?.article;
    

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: '2-digit',
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleDateString('en-IN', options);
    };
    
    console.log(current);

    return <>
        {/* nav bar */}
        <div>
        <img src={pageImage} alt="home" className="background-img" />
      <div className="container-fluid">
        <NewNavbar/>
        <section className="herohero">
          <h1 className="title-txt">{current ? current.title : "loading"}</h1>
        </section>
      </div>
        </div>

        {/* Article Page */}
        <div>
            <div className="article-img-container">
            <img className="article-pg-img" src={current ? current.imageUrl : "loading"}></img>
            <p className="article-pg-date">{formatDateTime(current ? current.createdTime : "loading")}</p>
            <p className="article-para">{current.details}</p>
            </div>
            
        </div>

        {/* Footer */}
        <Footer></Footer>
        
    </>
}

export default ArticlePage;