import articleHeader from "../../Assets/articleHeader.svg";
import logo from "../../Assets/logo.svg";
import NewNavbar from "../Home/NewNavbar";
import "./ArticleHeader.css";
import { useState } from "react";


const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

const toggleNavbar = () => {
    setIsOpen(!isOpen);
};

  return (
    <>
      <img src='https://demo-api.profcongress.in/v1/home/image/articles.png' alt="home" className="background-img" />
      <div className="container-fluid">
        <NewNavbar/>
        <section className="herohero">
          <h1 className="title-txt">Articles</h1>
        </section>
      </div>
    </>
  );
};

export default Header;
