import React, { useEffect, useState } from 'react';
import './MainContent.css'; // Create a CSS file for main content styling
import Verticals from './Verticals';
import { useNavigate } from 'react-router-dom';
import DownArrow from '../../Assets/downArrow.svg';
import Gallery from './Gallery';


const MainContent = ({ data, videos, veriticals, images }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 992);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleChange = () => {
        navigate('/watch')
    }

    const handleDetails = (card) => {
        navigate('/article-detail', { state: { card } })
    }

    const { messageTitle, message, youtubeId } = data;
    
    const cardsData = [
        { title: 'WHO', subtitle: 'ARE WE', content: data.whoWeAre },
        { title: 'WHAT', subtitle: 'WE DO', content: data.whatWeAre },
        { title: 'WHAT', subtitle: 'YOU CAN DO', content: data.whatWeCanDo },
        { title: 'WHAT', subtitle: 'WILL YOU GET', content: data.whatYouWillGet }
    ];


    if (isMobile) {
        // Mobile layout
        return (
            <div className='container-fluid'>
                <div className="middle">
                    <img src={data.middleImage} alt="home" className="middle-image img-fluid mt-1" />
                </div>
                <section className="bridge-gap">
                    <div className="info-cards">
                        {cardsData.map((card, index) => (
                            <div
                                key={index}
                                className="card"
                                style={{ color: '#3578B7', justifyContent: 'center', cursor: 'pointer' }}
                                onClick={() => handleDetails(card)}
                            >
                                {card.title} <br /> {card.subtitle}
                            </div>
                        ))}
                    </div>
                </section>

                <section className="video-message">
                    <h4 className='message-title'>{messageTitle}</h4>
                    <p className='message' style={{ textAlign: 'center' }}>{message}</p>
                    <div className="video">
                        {youtubeId ? (
                            <iframe
                                width="80%"
                                height="315" // Adjust height for mobile
                                src={`https://www.youtube.com/embed/${youtubeId}`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <img src="video-placeholder.png" alt="Video placeholder" />
                        )}
                    </div>
                </section>

                <Gallery images={images} />

                <Verticals veriticals={veriticals} />

                <section className="watch">
                    <div className="col-md-11 mt-4">
                        <h4 className='col' style={{ textAlign: 'left', display: 'flex', fontFamily: 'Cardo', fontSize: '34px', fontWeight: 700, color: '#3578B7', margin: 0, cursor: 'pointer' }} onClick={handleChange}>
                            Watch
                            <img src={DownArrow} alt="Down Arrow" style={{ width: '34px', paddingLeft: '7px' }} />
                        </h4>
                        <div className="vertical-cards row">
                            {videos.slice(0, 3).map((video) => (
                                <div className="vertical col-md-4" key={video.id}>
                                    <iframe
                                        width="450"
                                        height="215"
                                        src={`https://www.youtube.com/embed/${video.utubeId}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    return (
        <div className='container-fluid'>
            <div className="middle">
                <img src={data.middleImage} alt="home" className="middle-image img-fluid mt-1" />
            </div>
            <section className="bridge-gap">
                <div className="info-cards">
                    {cardsData.map((card, index) => (
                        <div
                            key={index}
                            className="card"
                            style={{ color: '#3578B7', justifyContent: 'center', cursor: 'pointer' }}
                            onClick={() => handleDetails(card)}
                        >
                            {card.title} <br /> {card.subtitle}
                        </div>
                    ))}
                </div>
            </section>

            <section className="video-message">
                <h4 className='message-title mb-3'>{messageTitle}</h4>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <p className='message'>{message}</p>
                        </div>
                        <div class="col">
                            <div className="video">
                                {/* Embed your video here */}
                                {youtubeId ? (
                                    <iframe
                                        width="80%"
                                        height="355"
                                        src={`https://www.youtube.com/embed/${youtubeId}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <img src="video-placeholder.png" alt="Video placeholder" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Gallery images={images} />

            <Verticals veriticals={veriticals} />

            <section className="watch">
                <div className="col-md-11 mt-4">
                    <h4 className='col' style={{ textAlign: 'left', display: 'flex', fontFamily: 'Cardo', fontSize: '34px', fontWeight: 700, color: '#3578B7', margin: 0, cursor: 'pointer' }} onClick={handleChange}>
                        Watch
                        <img src={DownArrow} alt="Down Arrow" style={{ width: '34px', paddingLeft: '7px' }} />
                    </h4>
                    <div className="vertical-cards row">
                        {videos.slice(0, 3).map((video) => (
                            <div className="vertical col-md-4" key={video.id}>
                                <iframe
                                    width="450"
                                    height="215"
                                    src={`https://www.youtube.com/embed/${video.utubeId}`}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MainContent;